.container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
    row-gap: 20px;
    grid-template-rows: 1fr 120px 1fr 120px;
    margin-top: 60px;
    align-items: stretch;
}

.container p {
    text-align: center;
    font-size: 44px;
    line-height: 130%;
}

.container a {
    color: inherit
}